
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'KanbanBoard',
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true
    },
    selected: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      states: [
        {value: "applicant", text: this.$vuetify.lang.t("$vuetify.worker_states.applicant")},
        {value: "web", text: this.$vuetify.lang.t("$vuetify.worker_states.web")},
        {value: "pre-registered", text: this.$vuetify.lang.t("$vuetify.worker_states.pre_registered")},
        {value: "supplements OK", text: this.$vuetify.lang.t("$vuetify.worker_states.supplement_ok")},
        {value: "candidate", text: this.$vuetify.lang.t("$vuetify.worker_states.candidate")},
        {value: "employee", text: this.$vuetify.lang.t("$vuetify.worker_states.employee")}
      ]
    }
  },
  methods: {
    getColumnItems(state: string) {
      return this.items.filter(item => item.state === state)
    },
    onDragStart(event: DragEvent, item: any) {
      if (event.dataTransfer) {
        event.dataTransfer.setData('itemId', item.id.toString())
      }
    },
    onDrop(event: DragEvent, newState: string) {
      if (event.dataTransfer) {
        const itemId = parseInt(event.dataTransfer.getData('itemId'))
        this.$emit('update-state', {itemId, newState})
      }
    }
  }
})
